import {Component, OnDestroy} from '@angular/core';
import {FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {catchError, EMPTY, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {EMAIL_VALIDATION_ERRORS, PASSWORD_VALIDATION_ERRORS, PSEUDO_VALIDATION_ERRORS} from '../../../constants/validation-errors';
import {AuthenticationService} from '../../../services/authentication.service';
import {ValidationErrorsComponent} from '../../validation-errors/validation-errors.component';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonTitle,
  IonToolbar,
  ModalController,
  ToastController,
} from '@ionic/angular/standalone';

@Component({
  selector: 'cmn-signup',
  templateUrl: './signup-page.component.html',
  styleUrls: ['./signup-page.component.scss'],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ValidationErrorsComponent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonContent,
    IonItem,
    IonInput,
  ],
  standalone: true,
})
export class SignupPageComponent implements OnDestroy {
  signupError: string;
  signupForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    pseudo: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(15)]),
    password: new UntypedFormControl('', [Validators.required]),
  });
  emailValidationErrors = EMAIL_VALIDATION_ERRORS;
  pseudoValidationErrors = PSEUDO_VALIDATION_ERRORS;
  passwordValidationErrors = PASSWORD_VALIDATION_ERRORS;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly toastController: ToastController,
    private readonly modalController: ModalController,
    private readonly authenticationV2Service: AuthenticationService,
  ) {}

  signup(): void {
    const {email, password, pseudo} = this.signupForm.value;
    this.authenticationV2Service
      .register(pseudo, email, password)
      .pipe(
        tap(() => {
          this.modalController.dismiss();
          this.successSignupToast();
        }),
        catchError((error) => {
          this.signupError = error.message;
          return EMPTY;
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  async successSignupToast(): Promise<void> {
    const toast = await this.toastController.create({
      message: '✅ Connecté',
      duration: 1500,
      position: 'top',
      translucent: true,
    });
    await toast.present();
  }

  dismiss(): void {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
