export const EMAIL_VALIDATION_ERRORS: {type: string; message: string}[] = [
  {type: 'pattern', message: 'Adresse email invalide.'},
  {type: 'required', message: 'Adresse email requise.'},
];

export const PSEUDO_VALIDATION_ERRORS: {type: string; message: string}[] = [
  {type: 'minlength', message: 'Votre pseudo doit comporter au moins 6 caractères.'},
  {type: 'maxlength', message: 'Votre pseudo doit comporter au plus 15 caractères.'},
  {type: 'required', message: 'Pseudo est requis.'},
];

export const PASSWORD_VALIDATION_ERRORS: {type: string; message: string}[] = [
  {type: 'minLength', message: 'Doit comporter au moins 8 caractères.'},
  {type: 'required', message: 'Mot de passe requis.'},
];
