<ion-header>
  <ion-toolbar>
    <ion-title>Création de compte</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">Cancel</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding-horizontal">
  <form [formGroup]="signupForm">
    <ion-item>
      <ion-input
        autocomplete="off"
        formControlName="pseudo"
        label="Pseudo"
        labelPlacement="floating"
        maxlength="15"
        minlength="6"
        type="text"
      />
    </ion-item>

    <cmn-validation-errors [formControlRef]="signupForm.get('pseudo')" [validationErrors]="pseudoValidationErrors" />

    <ion-item class="ion-margin-top">
      <ion-input formControlName="email" label="Email" labelPlacement="floating" type="email" />
    </ion-item>

    <cmn-validation-errors [formControlRef]="signupForm.get('email')" [validationErrors]="emailValidationErrors" />

    <ion-item class="ion-margin-top">
      <ion-input formControlName="password" label="Mot de passe" labelPlacement="floating" minlength="8" type="password" />
    </ion-item>

    <cmn-validation-errors [formControlRef]="signupForm.get('password')" [validationErrors]="passwordValidationErrors" />

    <div class="ion-padding-horizontal">
      <div class="form-error">{{ signupError }}</div>
      <ion-button (click)="signup()" [disabled]="!signupForm.valid" expand="block" fill="outline" type="submit">
        Créer un compte
      </ion-button>
    </div>
  </form>
</ion-content>
